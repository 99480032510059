import React from "react"
import LayoutThird from "../../components/common/layout-third";
import SEO from "../../components/common/seo";
import SignatureNav from "../../components/common/signature-nav";
import Partners from "../../components/common/partners";
import Interested from "../../components/signature/interested";
import ModalContactSales from "../../components/modals/modal-contact-sales";
import ContactBox from "../../components/common/contact-box";
import {graphql} from "gatsby";
import ModalWhitePaper from "../../components/modals/modal-white-paper";
import ReactMarkdown from "react-markdown";
import {customElements} from "../../utils/global-functions";
import rehypeRaw from "rehype-raw";

const LegalidadPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiSignatureLegalidad.edges[0].node;
    const customVerificationElements = (pClassName = '', ulClassName = '', liClassName = '') => {
        return {
            p: ({node, ...props}) => <p className={pClassName} {...props} />,
            ul: ({node, ...props}) => <ul className={ulClassName} {...props} />,
            li: ({node, ...props}) => <li className={liClassName} {...props} />,
        }
    }

    return (
        <LayoutThird pageContext={pageContext} cookies={data.cookies} favicon="favicon-sig.png">
            <SEO lang={lang} title={page.seo.title} image={page.seo.image.localFile.publicURL} description={page.seo.meta_description}/>

            <SignatureNav pageContext={pageContext}/>

            <section id="product-top" className="content pt-13 pb-8 py-5 ahorro">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 mt-7 mb-3" data-aos="fade-right">
                            <h3 className="font-weight-bold spacing">
                                {page.title}
                            </h3>

                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 spacing mt-6 mb-2', 'default-link')}/>
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-6 mt-7" data-aos="fade-down">
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor3">
                                <img src={require("../../img/products/ic_decoration.svg").default} alt=""/>
                            </div>

                            <img src={require("../../img/products/sign.png").default} width="100%" className="img-fluid mt-2 mb-12 img-prod" alt=""/>
                        </div>
                    </div>

                    <br/>
                </div>

                <br/>
            </section>

            <section className="position-relative py-8 py-md-9 mb-5 ahorro bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">
                                <div className="device device-iphonex" data-aos="fade-up">
                                    <img src={require("../../img/products/signature-m5.png").default} className="device-screen" alt="..."/>
                                    <img src={require("../../img/products/iphonex.svg").default} className="img-fluid ip" alt="..."/>
                                </div>

                                <div className="device device-iphonex" data-aos="fade-down">
                                    <img src={require("../../img/products/signature-m6.png").default} className="device-screen" alt="..."/>
                                    <img src={require("../../img/products/iphonex.svg").default} className="img-fluid ip" alt="..."/>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-5 offset-lg-1" data-aos="fade-up">
                            <h1 className="font-weight-bold spacing">
                                {page.advanced_title}
                            </h1>

                            <br/>

                            <ReactMarkdown
                                children={(typeof String.prototype.replaceAll !== "undefined") ? page.advanced_description?.replaceAll('????', '👉') : page.advanced_description}
                                rehypePlugins={[rehypeRaw]}
                                components={customElements('font-size-sm spacing mt-2 mb-4')}/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="f3 mb-6">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9 text-center">
                            <h4><span className="badge rounded-pill bg-tecalis mt-7 py-2 pr-3 pl-3 spacing text-white">{page.verification_title}</span></h4>
                            <br/><br/>

                            <h2 className="font-weight-bold spacing" data-aos="fade-up">
                                {page.verification_subtitle}
                            </h2>

                            <br/><br/>
                        </div>

                        <div className="col-12 col-md-10 col-lg-9" data-aos="fade-up">
                            <ReactMarkdown children={page.verification_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 spacing', 'default-link')}/>
                        </div>

                        <div className="col-12 col-md-10 col-lg-9 mt-5 mb-10" data-aos="fade-up">
                            <div className="card accordion bg-grey" id="featuresAccordion">
                                <div className="card-body">
                                    <div className="list-group list-group-flush">
                                        {page.verification_items.map((item, i) => {
                                            return <div className="list-group-item bg-grey" key={i}>
                                                <a className="d-flex align-items-center text-reset text-decoration-none" data-toggle="collapse" href="#featuresOne" role="button"
                                                   aria-expanded="false" aria-controls="featuresOne">
                                                    <div className="mr-auto">
                                                    <span className="mr-4 ml-4 font-weight-bold text-tecalis spacing size-collapse">
                                                        {item.title}
                                                    </span>
                                                    </div>

                                                    <span className="collapse-chevron text-muted ml-4">
                                                    <i className="fe fe-lg fe-chevron-down"/>
                                                </span>
                                                </a>

                                                <div className="collapse" id="featuresOne" data-parent="#featuresAccordion">
                                                    <div className="py-2 py-mb-6 spacing check-list">
                                                        <br/>
                                                        <ReactMarkdown children={item.description}
                                                                       rehypePlugins={[rehypeRaw]}
                                                                       components={customVerificationElements('font-size-lg', 'spacing mb-5 font-size-lg')}/>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 links bg-negro-seccion">
                <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor">
                    <img src={require("../../img/products/ic_decoration_white.svg").default} alt=""/>
                </div>

                <div className="container">
                    <h3 align="center"><span className="badge rounded-pill bg-white mt-8 py-2 pr-3 pl-3 spacing">{page.warranty_title}</span></h3>

                    <div className="links-wrapper">
                        <div className="row links-card">
                            {page.warranty_items.map((item, i) => {
                                return <div className="col-xl-12" key={i}>
                                    <div className="card hvr-float mb-5">
                                        <div className="card-body">
                                            <h3 className="text-black-80 spacing mt-5 mb-4 pl-5 pr-5">
                                                {(typeof String.prototype.replaceAll !== "undefined") ? item.title?.replaceAll('????', '👉') : item.title}
                                            </h3>

                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} components={customElements('spacing mt-2 mb-4 pl-5 pr-5 font-size-lg')}/>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 cta border-bottom">
                <div className="container">
                    <div className="row mt-5 mb-10">
                        <div className="col-xl-5" data-aos="fade-up">
                            <img src={require("../../img/products/unlock.svg").default} alt="" width="450px" className="img-fluid mt-9 mb-5"/>
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-6 check-list" data-aos="fade-down">
                            <h3 className="font-weight-bold spacing">
                                {page.compliance_title}
                            </h3>

                            <ReactMarkdown children={page.compliance_description}
                                           rehypePlugins={[rehypeRaw]}
                                           components={customVerificationElements('font-size-lg text-black-80 spacing mb-2', 'spacing mb-5 font-size-lg')}/>
                        </div>
                    </div>
                </div>
            </section>

            <Interested pageContext={pageContext}/>

            <Partners pageContext={pageContext}/>

            <ContactBox pageContext={pageContext} product="Signature"/>

            <ModalContactSales pageContext={pageContext} product="Signature"/>

            <ModalWhitePaper pageContext={pageContext}/>

            {/*<ModalSignatureDemo/>*/}
        </LayoutThird>
    )
}

export default LegalidadPage

export const legalidadPageQuery = graphql
    `query($lang: String) {
        allStrapiSignatureLegalidad ( filter: { locale: { eq: $lang } } )
        {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    description
                    advanced_title
                    advanced_description
                    verification_title
                    verification_subtitle
                    verification_description
                    verification_items {
                        order
                        title
                        description
                    } 
                    warranty_title
                    warranty_items {
                        order
                        title
                        description
                    } 
                    compliance_title
                    compliance_description
                }
            }
        }
        cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
}`;
